Vue.component('in-bool', {
  data: function () { return {
    content: null
  } },
  props: ['value', 'readOnly', 'label', 'trueFalse', 'small'],
  watch: {
    value (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.content = this.value ? true : false
        this.$emit('input', this.value);
      }
    },
    content (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.value = this.trueFalse ? this.content : (this.content ? 1 : 0)
      }
    }
  },
  methods: {
    inverseValue () {
      if (!this.readOnly) {
        console.log("inverse")
        if (this.trueFalse) {
          this.value = !this.value
        } else {
          this.value = (!this.value ? 1 : 0)
        }
      }
    }
  },
  mounted: function () {
    this.content = this.value ? true : false
  },
  template: /*html*/`
    <div class="in-bool" :class="[small && 'small']">
      <a href="#" class="switch" @click.prevent="inverseValue" :class="content ? 'checked' : ''"><span></span></a>
      <a href="#" @click.prevent="inverseValue" v-if="label">{{ label }}</a>
    </div>
  `
})