Vue.component('in-text-ac', {
  props: ['emptyLabel', 'src'],
  data: function () { return {
    content: ""
  }},
  methods: {
    sendValue: function (selectedValue) {
      this.$emit('select', selectedValue)
    },
    focus: function () {
      let me = this
      Vue.nextTick(function () { document.querySelector("#input" + me._uid).focus()  })
    },
    setValue: function (value) {
      this.content = value
    }
  },
  watch: {
    src: function (newValue, oldValue) {
      this.content = ""
    }
  },
  mounted () {
    // console.log("#input" + this._uid);
    let me = this
    let selector = "#input" + me._uid

    new autoComplete({
      selector: selector,
      placeHolder: me.emptyLabel,
      data: {
        src: () => {
          document.querySelector(selector).setAttribute("placeholder", "Chargement...")
          return new Promise((resolve, reject) => {
            me.server.get(me.src + '/' + me.content).then((data) => {
              document.querySelector(selector).setAttribute("placeholder" ,me.emptyLabel)
              resolve(data)
            })
          });
          // return await this.server.get('/communes')
        },
        key: ['label']
      },
      resultsList: {
          noResults: (list, query) => {
              // Create "No Results" message list element
              const message = document.createElement("li");
              message.setAttribute("class", "no_result");
              // Add message text content
              message.innerHTML = `<span>Aucun résultat pour "${query}"</span>`;
              // Add message list element to the list
              list.appendChild(message);
          },
      },
      resultItem: {
          highlight: {
              render: true
          }
      },
      onSelection: (feedback) => {
        console.log('feedback :>> ', feedback.selection.value);
        me.content = feedback.selection.value.label
        me.sendValue(feedback.selection.value)
      }
    });


    document.querySelector(selector).addEventListener("input", function (event) {
      me.$emit("unselect")
    })
    // const autoCompleteJS = 

    //this.$refs['input']
  },
  template: /*html*/`
  <div>
    <div class="autoComplete_wrapper">
      <input type="text" dir="ltr" spellcheck=false autocorrect="off" autocomplete="off" autocapitalize="off" :id="'input' + _uid" class="in-ac" v-model="content">
    </div>
  </div>
  `
})