PAGES['config'] = Vue.component('page-config', {
  data () { return { 
    perimetres: [],
    newAdresse: {
      num_perimetre: null,
      commune: null,
      voie: null,
      tous: 0,
      debut: null,
      fin: null,
      pair: 0,
      impair: 0
    }
  }},
  methods: {
    saveNew () {
      // this.$refs.invoie.setValue("Salut")
      this.server.post('/config/perimetre/save', this.newAdresse).then(function () {
        document.location.reload()
        console.log("ici")
      })
    },
    set(field, value) {
      let me = this
      if (field === 'commune') {
        this.newAdresse.voie = null
        this.newAdresse.debut = null
        this.newAdresse.fin = null
        this.$refs.invoie.focus()
      } else if (field === 'voie') {
        this.newAdresse.debut = null
        this.newAdresse.fin = null
        this.$refs.innod.focus()
      } else if (field === 'debut') {
        this.$refs.innof.focus()
      }
      this.newAdresse[field] = value.value
    },
    unset (field) {
      if (field === 'commune') {
        this.newAdresse.voie = null
        this.newAdresse.debut = null
        this.newAdresse.fin = null
      } else if (field === 'voie') {
        this.newAdresse.debut = null
        this.newAdresse.fin = null
      }
      this.newAdresse[field] = null
      this.pdl = 0
    },
    updatePairImpair (pair) {
      if (pair && this.newAdresse.pair) {
        this.newAdresse.impair = 0
      }
      if (!pair && this.newAdresse.impair) {
        this.newAdresse.pair = 0
      }
    },
    setCurrentP (p) {
      this.newAdresse.num_perimetre = p.num_perimetre
      this.$refs.incommune.setValue(p.nom_commune)
      this.newAdresse.commune = p.commune
      let me = this
      this.$nextTick(function () {
        me.$refs.invoie.setValue(p.nom_voie)
        me.newAdresse.voie = p.voie
        me.$nextTick(function () {
          me.$refs.innod.setValue(p.noDebut)
          me.$refs.innof.setValue(p.noFin)
          me.newAdresse.debut = p.debut
          me.newAdresse.fin = p.fin
          me.newAdresse.tous = p.tous
          me.newAdresse.impair = p.impair
          me.newAdresse.pair = p.pair
        })
      })
    }
  },
  mounted () {
    let me = this
    this.server.get('/config/perimetre/list').then((data) => {
      console.log('data :>> ', data);
      me.perimetres = data
    })
  },
  template: /*html*/`
    <div class="page-config">
      <h1>Permis de louer - configuration</h1>
      <table>
        <thead>
          <tr>
            <th>Commune</th>
            <th>Voie</th>
            <th>Tous</th>
            <th>De</th>
            <th>À</th>
            <th>Pair</th>
            <th>Impair</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="p in perimetres">
            <td>{{ p.nom_commune }}</td>
            <td>{{ p.nom_voie }}</td>
            <td>{{ p.tous ? 'oui' : 'non' }}</td>
            <td>{{ p.tous ? '-' : p.noDebut }}</td>
            <td>{{ p.tous ? '-' : p.noFin }}</td>
            <td>{{ p.pair ? 'oui' : 'non' }}</td>
            <td>{{ p.impair ? 'oui' : 'non' }}</td>
            <td>
              <a href="#" class="button blue" @click.prevent="setCurrentP(p)">Modifier</a>
            </td>
          </tr>
          <tr>
            <td>
              <in-text-ac ref="incommune" @select="set('commune', $event)" @unselect="unset('commune')" emptyLabel="Commune" src="/communes" class="commune" :class="[newAdresse.commune && 'fixed']"></in-text-ac>
            </td>
            <td>
              <in-text-ac class="in-voie" v-show="newAdresse.commune" ref="invoie" @select="set('voie', $event)" @unselect="unset('voie')" emptyLabel="Voie" :src="'/voies/' + newAdresse.commune" :class="[newAdresse.voie && 'fixed']"></in-text-ac>
            </td>
            <td>
              <in-bool v-model="newAdresse.tous"></in-bool>
            </td>
            <td>
              <in-text-ac class="in-numero" v-show="newAdresse.voie && !newAdresse.tous" ref="innod" @select="set('debut', $event)" @unselect="unset('debut')" emptyLabel="Numéro" :src="'/nos/' + newAdresse.commune + '/' + newAdresse.voie" :class="[newAdresse.debut && 'fixed']"></in-text-ac>
              <span v-show="newAdresse.tous">-</span>
            </td>
            <td>
              <in-text-ac class="in-numero" v-show="newAdresse.voie && !newAdresse.tous" ref="innof" @select="set('fin', $event)" @unselect="unset('fin')" emptyLabel="Numéro" :src="'/nos/' + newAdresse.commune + '/' + newAdresse.voie" :class="[newAdresse.fin && 'fixed']"></in-text-ac>
              <span v-show="newAdresse.tous">-</span>
            </td>
            <td>
              <in-bool v-model="newAdresse.pair" @input="updatePairImpair(true)"></in-bool>
            </td>
            <td>
              <in-bool v-model="newAdresse.impair" @input="updatePairImpair(false)"></in-bool>
            </td>
            <td>
              <a href="#" class="button blue" :class="[(!(newAdresse.commune && newAdresse.voie && ((newAdresse.debut && newAdresse.fin) || newAdresse.tous))) && 'disabled']" @click.prevent="saveNew">Enregistrer</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  `
})