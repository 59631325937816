PAGES['accueil'] = Vue.component('page-accueil', {
  data () { return { 
    adresse: {
      commune: null,
      voie: null,
      numero: null,
      pasDeNumero: false
    },
    adresseComplete: "1 Rue du Moulin 54670 Millery",
    pdl: 0
  }},
  methods: {
    set(field, value) {
      let me = this
      if (field === 'commune') {
        this.adresse.voie = null
        this.adresse.numero = null
        this.$refs.invoie.focus()
      } else if (field === 'voie') {
        this.adresse.numero = null
        this.adresse.pasDeNumero = false
        let me = this
        this.server.get('/nos/' + this.adresse.commune + '/' +  value.value).then((data) => {
          console.log('data :>> ', data);
          if (data.length === 1) {
            me.adresse.pasDeNumero = true
            me.server.get('/adressecomplete/' + data[0].value).then((data) => {
              me.adresseComplete = data.adresseComplete
            })
            me.server.get('/pdl/' + data[0].value).then((data) => {
              me.pdl = data.pdl
            })
          } else {
            me.$refs.inno.focus()
          }
        })
      
      } else if (field === 'numero') {
        this.server.get('/adressecomplete/' + value.value).then((data) => {
          me.adresseComplete = data.adresseComplete
        })
        this.server.get('/pdl/' + value.value).then((data) => {
          me.pdl = data.pdl
        })
      }
      this.adresse[field] = value.value
    },
    unset (field) {
      if (field === 'commune') {
        this.adresse.voie = null
        this.adresse.numero = null
      } else if (field === 'voie') {
        this.adresse.numero = null
      }
      this.adresse[field] = null
      this.pdl = 0
    }
  },
  mounted () {
    let me = this
    this.$refs.incommune.focus()
    // const autoCompleteJS = new autoComplete({});
    // this.server.get("/test").then((data) => { me.total = data.total })
  },
  template: /*html*/`
    <div class="page-accueil">
      <h1>Permis de louer</h1>
      <div>
        <h2><img src="/assets/images/location_32.png">Renseigner l'adresse du logement</h2>
        <in-text-ac ref="incommune" @select="set('commune', $event)" @unselect="unset('commune')" emptyLabel="Commune" src="/communes" class="commune" :class="[adresse.commune && 'fixed']"></in-text-ac>
        <div class="hint" v-show="!adresse.commune">Saisir les premières lettres de la commune recherchée.</div>

        <in-text-ac v-show="adresse.commune" ref="invoie" @select="set('voie', $event)" @unselect="unset('voie')" emptyLabel="Voie" :src="'/voies/' + adresse.commune" :class="[adresse.voie && 'fixed']"></in-text-ac>
        <div v-show="adresse.commune && !adresse.voie" class="hint">Saisir les premières lettres de la voie recherchée.</div>

        <in-text-ac v-show="adresse.voie && !adresse.pasDeNumero" ref="inno" @select="set('numero', $event)" @unselect="unset('numero')" emptyLabel="Numéro" :src="'/nos/' + adresse.commune + '/' + adresse.voie" :class="[adresse.numero && 'fixed']"></in-text-ac>
        <div v-show="adresse.voie && !adresse.numero && !adresse.pasDeNumero" class="hint">Saisir les premier chiffres du numéro recherché.</div>

        <h2><img src="/assets/images/question_32.png">Résultats (donnés à titre indicatif)</h2>
        <div v-show="!adresse.numero && (adresse.voie && !adresse.pasDeNumero)" class="resultat no-result">
          Merci de saisir une adresse pour obtenir un résultat. 
        </div>
        <div class="resultat" :class="[pdl === 1 && 'ko', pdl === 2 && 'ok']" v-show="pdl !== 0">
          <div>
            <img :src="pdl === 1 ? '/assets/images/check.svg' : '/assets/images/information.svg'">
          </div>
          <div>
            L'adresse <b>{{ adresseComplete }}</b><br>
            <span v-show="pdl === 1">n'est <b>pas</b> concernée par le permis de louer</span>
            <span v-show="pdl === 2">est concernée par le permis de louer</span>
          </div>
        </div>
      </div>
    </div>
  `
})